import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Avatar, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components

// ----------------------------------------------------------------------

TrackerTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    // onDeleteRow: PropTypes.func,
    setDeleteDialog: PropTypes.func,
    setDeleteId: PropTypes.func,
    handlePasswordChange: PropTypes.func,
};

export default function TrackerTableRow({
    row,
    selected,
    onEditRow,
    onSelectRow,
    handlePasswordChange,
    // onDeleteRow,
    setDeleteDialog,
    setDeleteId,
}) {
    const theme = useTheme();

    const { first_name, last_name, profile_picture, position, latest_task_logs, roles, latestAvailabilityStatus, teams } = row;
    const name = `${first_name} ${last_name}`;

    const [openMenu, setOpenMenuActions] = useState(null);

    const handleOpenMenu = (event) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    return (
        <TableRow hover selected={selected}>
            {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={name} src={profile_picture} sx={{ mr: 2 }} />
                <Typography variant="subtitle2" noWrap>
                    {name}
                </Typography>
            </TableCell>

            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>

                <Typography variant="subtitle2" noWrap>
                    {Object.keys(latestAvailabilityStatus).length === 0 ? "---" : latestAvailabilityStatus?.title}
                </Typography>

            </TableCell>
            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {roles?.map((role) => (
                    <Typography key={role?.id} variant="subtitle2" noWrap>
                        {role?.name}
                    </Typography>
                ))}
            </TableCell>
            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {teams?.map((team) => (
                    <Typography key={team?.id} variant="subtitle2" noWrap>
                        {team?.name}
                    </Typography>
                ))}
            </TableCell>

            <TableCell align="left">
                <Typography variant="subtitle2" noWrap>
                    {latest_task_logs?.last_log_time}
                </Typography>
            </TableCell>
        </TableRow>
    );
}
