export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ALLUSER = 'ALLUSER';
export const ALLPRIORITY = 'ALLPRIORITY';
export const ALLSTATUS = 'ALLSTATUS';
export const SETCOUNT = 'SETCOUNT';
export const GETUSER = 'GETUSER';
export const GETPROJECT = 'GETPROJECT';
export const GETPRIORITY= 'GETPRIORITY';
export const GETTEAMUSER= 'GETTEAMUSER';
export const UPDATE_LAST_UPDATE_TIME ="UPDATE_LAST_UPDATE_TIME"
export const GETLOCALIGNORE ="GETLOCALIGNORE"