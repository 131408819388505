import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import { Divider, InputAdornment, Stack, TextareaAutosize } from '@mui/material';
import { setLocalIgnore } from 'src/redux/actions/login';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect, useMemo } from 'react';
import AxiosAll from 'src/services/axiosAll';
import { useSnackbar } from 'notistack';
import { setCount } from 'src/redux/actions/reviewCount';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment, { duration } from 'moment';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { findTimeDifference } from 'src/core';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from '../../components/hook-form';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddMeetingLogsForm.propTypes = {
  openLog: PropTypes.bool,
  onSetMeetingInfo: PropTypes.func,
  onClose: PropTypes.func,
  onNextStep: PropTypes.func,
  onCreateBilling: PropTypes.func,
  editTask: PropTypes.object,
};

function AddMeetingLogsForm({ breakstart, openLog, onClose, editTask, onSetMeetingInfo }) {
  const NewAddressSchema = Yup.object().shape({
    comment: Yup.string().notRequired('Description is required'),
    duration: Yup.string().required('Duration is required').matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format. Please use HH:MM in 12-hour format.'),
    project: Yup.string().when('type', {
      is: (type) => type === "7" || type === "9",
      then: Yup.string().required('Project is required'),
      otherwise: Yup.string().notRequired(),
    }),
  });
  const typeData = [
    { id: '7', title: 'Discussion' },
    { id: '8', title: 'Internal Meeting' },
    { id: '9', title: 'External Meeting' },
  ]
  const { userDetails, ignore } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const { rcount } = useSelector((state) => state.reviewReducer);
  const [isLog, setIsLog] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [customTime, setCustomTime] = useState('00:00');
  const [defaultBreak, setDefaultbreak] = useState('');
  const [countdown, setCountdown] = React.useState(300); // 5 minutes countdown
  const [showData, setShowData] = useState({})
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState('');
  const [active, setActive] = useState(false);
  const [startTime, setStartTime] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [project, setProject] = useState([]);
  const [endTime, setEndTime] = useState("00:00");
  const [showPrompt, setShowPrompt] = useState(false); // State for controlling prompt visibility

  const dispatch = useDispatch();

  // Function to handle the prompt's confirm action
  const handleConfirmClose = () => {
    dispatch(setLocalIgnore(true));
    setShowPrompt(false); // Close the prompt modal
    onClose();
  };


  // Function to handle the prompt's cancel action
  const handleCancelClose = () => {
    setShowPrompt(false); // Close the prompt modal
  };

  // Function to open the prompt modal
  const handleOpenPrompt = () => {
    setShowPrompt(true);
  };

  const user_role_id = userDetails?.user?.role_id;

  const navigate = useNavigate();


  const defaultValues = useMemo(
    () => ({
      duration: '',
      comment: '',
      project_id: project[0]?.id || '',
      type:"7",
      availability_status_id: 7
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project]
  );

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch()


  const handleInputChange = (event) => {

    setMessage(event.target.value);
  };

  const handleBreakClick = () => {
    setIsLog(true);
    setOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClose();
    setCustomTime('00:00');
    setDefaultbreak('');
  };

  const handleCustomTimeChange = (event) => {
    setActive(false);
    setDefaultbreak('');
    const value = event.target.value;
    if (value) {
      const isValidTime = validateTimeFormat(value);
      if (isValidTime) {
        setValue('duration', value)
        setCustomTime(value);
        setError('');
      } else {
        setValue('duration', '00:00')
        setCustomTime('00:00');
        setError('Invalid time format. Please use HH:MM in 12-hour format.');
      }
    } else {
      setError('');
      setCustomTime('00:00');
    }
  };



  const validateTimeFormat = (time) => {
    const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/; // HH:MM format
    return regex.test(time);
  };


  const handleBackButtonClick = () => {
    setIsLog(false);
    onClose();
  };

  useEffect(() => {
    if (rcount?.availability_status?.availability_status_id === 7 || rcount?.availability_status?.availability_status_id === 8 || rcount?.availability_status?.availability_status_id === 9) {
      setIsLog(true);
      setCustomTime(rcount?.availability_status?.duration);
      const dateObject = new Date(rcount?.availability_status?.created_at);
      setStartTime(dateObject);
    }
  }, [rcount]);

  const handleOnClose = () => {
    reset(defaultValues)
    onClose()
    setStartTime("00:00");
    setEndTime("00:00")
  }


  const afterCheck = async (value) => {
    // eslint-disable-next-line no-debugger
    const [hours, minutes] = customTime?.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;
    const data = {
      comment: value?.comment,
      duration: active ? defaultBreak : totalMinutes,
      availability_status_id: value?.type,
      project_id: value?.project
    };
    try {
      const response = await AxiosAll('post', `/availability`, data, token);
      if (response?.status === 201) {

        setShowData(response?.data?.data)
        setIsLog(true);
        setCustomTime(response?.data?.data?.duration);
        const dateObject = new Date(response?.data?.data?.created_at);
        setStartTime(dateObject);
        enqueueSnackbar(`${response?.data?.message}`, { variant: 'success' });
        reset(defaultValues)
        onSetMeetingInfo(response?.data?.data?.title);
      } else {
        setIsLog(true);
        enqueueSnackbar(response?.data?.message, { variant: 'error' });
      }
    } catch (error) {
      if (error?.data?.message) {
        enqueueSnackbar(error?.data?.message, { variant: 'error' })
      }
    }
    setLoading(false);
  }


  const onSubmit = async (value) => {
    setLoading(true);

    if ((values?.type === "7" || values?.type === "9") && values?.project === "") {
      enqueueSnackbar("Please add project.", { variant: 'error' });
      setLoading(false);
    }
    if (values?.duration === "") {
      enqueueSnackbar("Please add Duration", { variant: 'error' });
      setLoading(false);
    }

    else {
      afterCheck(value);
    }
  };
  const getProject = async () => {
    const response = await AxiosAll('get', '/common/projects', {}, token);
    if (response?.status === 200) {
      setProject(response?.data);
    }
  };

  useEffect(() => {
    getProject();
  }, [openLog]);


  const handleBackTOWork = async () => {
    setLoading(true);
    try {
      const res = await AxiosAll(
        'post',
        `/availability`,
        {
          availability_status_id: 6,
        },
        userDetails?.token
      );
      const data = res?.data?.data?.data;

      getCounts();
      if (res?.status === 201) {
        setIsLog(false);
        handleBackButtonClick();
        setCustomTime('00:00');
        setDefaultbreak('');
        setActive(false);
        onClose();
        setMessage('')
        enqueueSnackbar(`${res?.data?.message}`, { variant: 'success' });
        navigate('/kanban')
        dispatch(setLocalIgnore(false));
       
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCounts = async () => {
    const response = await AxiosAll('get', '/counts', {}, userDetails?.token);
    const { data } = response;
    dispatch(setCount(data));
  };

  return (
    <div>
      <BootstrapDialog
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={openLog}
      >
        {' '}
        {isLog ? (
          <>

            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {showData?.title
                ? showData.title
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                  .join(" ")
                : ""}
              {(user_role_id === 1 || user_role_id === 3 || user_role_id === 4) && (
                <IconButton
                  aria-label="close"
                  onClick={handleOpenPrompt}
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </DialogTitle>

            <Divider sx={{ mx: 2 }} />
          </>
        ) : (
          <DialogTitle style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              {editTask ? "Edit Log" : "Add Log"}
            </div>


          </DialogTitle>
        )}

        <DialogContent dividers>
          {isLog ? (
            <div>
              {customTime !== 0 ? (
                <>
                  <Typography variant="subtitle1" sx={{ mt: 1 }}>
                    Currently You’re in a {customTime < 60 ? `${customTime} Minutes` : `${customTime / 60} Hour`}   {showData?.title
                      ? showData.title
                        .split(" ")
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(" ")
                      : ""}.
                  </Typography>

                  {showData?.comment && <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 500 }}>
                    Comment : {showData?.comment && showData?.comment}
                  </Typography>}
                </>
              ) : (
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  Currently You’re in a Meeting
                </Typography>
              )}
              {customTime !== 0 ? (
                <Stack direction="row" spacing={4} sx={{ mb: 4, mt: 3 }}>
                  <Typography variant="subtitle1">
                    {' '}
                    StartTime :{' '}
                    <span style={{ fontWeight: '400' }}>
                      {new Date(startTime).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      })}
                    </span>
                  </Typography>
                  <Typography variant="subtitle1">
                    EndTime:{' '}
                    <span style={{ fontWeight: '400' }}>
                      {new Date(new Date(startTime).getTime() + customTime * 60 * 1000).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      })}
                    </span>
                  </Typography>{' '}
                </Stack>
              ) : null}
              <DialogActions>
                <Button variant="contained" onClick={handleBackTOWork} sx={{ textTransform: 'inherit' }}>
                  {loading ? 'Loading...' : 'Back to Task'}
                </Button>
              </DialogActions>
            </div>
          ) : (
            <>

              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                  <Stack spacing={3}>

                    <div>
                      <LabelStyle>Type</LabelStyle>
                      <RHFSelect
                        disabled={!!editTask}
                        name="type"
                        defaultValue={typeData[0]?.id} // Set initial value
                      >
                        {typeData?.map((data, index) => (
                          <option key={index} value={data?.id}>
                            {data?.title}
                          </option>
                        ))}
                      </RHFSelect>


                      {(values?.type === "7" || values?.type === "9") && (
                        <div style={{ marginTop: "25px" }}>
                          <LabelStyle>Project*</LabelStyle>
                          <RHFSelect disabled={!!editTask} name="project">
                            <option value="">Select Project</option>
                            {project?.map((data, index) => (
                              <option key={index} value={data?.id}>
                                {data?.title}
                              </option>
                            ))}
                          </RHFSelect>
                        </div>
                      )}
                    </div>
                    <Stack direction="row" spacing={2}>
                      <div style={{ width: "100%" }}>
                        <LabelStyle>Duration*</LabelStyle>

                        <InputMask maskChar={null} mask="99:99" onChange={handleCustomTimeChange} name='duration'>
                          {(inputProps) => (
                            <TextField
                              {...inputProps}
                              name='duration'
                              fullWidth
                              error={errors?.duration?.message}
                              helperText={errors?.duration?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography variant="body2">HH:MM</Typography>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </InputMask>
                      </div>
                    </Stack>
                    <div style={{ width: "100%" }}>
                      <LabelStyle>Message</LabelStyle>
                      <RHFTextField name="comment" placeholder="Write something..." multiline rows={4} />
                    </div>

                  </Stack>
                </DialogContent>

                <DialogActions>
                  <LoadingButton type="submit" variant="contained" loading={loading}>
                    Submit
                  </LoadingButton>
                  <Button color="inherit" variant="outlined" onClick={handleOnClose}>
                    Cancel
                  </Button>
                </DialogActions>
              </FormProvider>

            </>
          )}
        </DialogContent>


        {/* Prompt Modal */}
        <Dialog   fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title" open={showPrompt} onClose={handleCancelClose}>
          <DialogTitle style={{fontSize: "20px", paddingBlock:"20px"}} sx={{ m: 0, p: 2 }} id="customized-dialog-title">Are you sure?</DialogTitle>
          <Divider sx={{ mx: 2 }} />
          <DialogContent dividers>
            <Typography variant="subtitle1"  sx={{ mt: 1 , py:5}}>Closing this modal lets you update the status, create tasks, and maintain logs. Ensure timely closure for effective time management.</Typography>
          </DialogContent>
          <DialogActions style={{paddingBlock:"30px"}}>
            <Button onClick={handleCancelClose} color="inherit" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirmClose} color="error" variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

      </BootstrapDialog>
    </div>
  );
};
export default AddMeetingLogsForm;

